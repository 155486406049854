// libs
import React, { ReactElement } from 'react';

// components
import { Flex } from 'theme-ui';
import { FeatureInfo } from './components/FeatureInfo';
import { SubFeatures } from './components/subFeatures';
import { Title } from '@sprinklr/shared-lib/components/Title';

// types
import { CTA } from '@sprinklr/shared-lib/@types/entities';
import { MediaProps } from '@sprinklr/shared-lib/components/media/types';
import { SubFeature } from './types';

type HeroWithSubFeaturesProps = {
  title: string;
  description: string;
  icon: { icon: string };
  tag: string;
  subTitle: string;
  subDescription?: string;
  cta?: CTA;
  media: MediaProps;
  subFeatures?: SubFeature[];
};

export const HeroWithSubFeatures = (
  props: HeroWithSubFeaturesProps,
): ReactElement => {
  const {
    title,
    description,
    icon,
    tag,
    subTitle,
    subDescription,
    media,
    cta,
    subFeatures,
  } = props;

  return (
    <Flex
      sx={{
        mx: 'auto',
        flexDirection: 'column',
        alignItems: 'center',
        gap: ['20px', '32px', '72px'],
      }}
    >
      <Title icon={icon} title={title} description={description} />
      <FeatureInfo
        title={tag}
        description={subTitle}
        subDescription={subDescription}
        media={media}
        cta={cta}
      />
      <SubFeatures subFeatures={subFeatures} />
    </Flex>
  );
};
