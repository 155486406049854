// libs
import React, { ReactElement } from 'react';

// components
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui';
import { CallToAction } from '@sprinklr/shared-lib';
import { Icon } from '@sprinklr/shared-lib/components/icon';
import { ColoredIcon } from '../ColoredIcon';

// types
import { SubFeature } from '../../types';

const CTA_ICON_STYLES: ThemeUIStyleObject = {
  maxHeight: '10px',
  width: '10px',
  marginLeft: '4px',
};

export const SubFeatureCard = ({
  subFeature,
}: {
  subFeature: SubFeature;
}): ReactElement => {
  const { icon, title, description, cta } = subFeature;

  return (
    <Flex sx={{ flexDirection: 'column', gap: '8px' }}>
      <Flex
        sx={{
          flexDirection: ['row', null, 'column'],
          alignItems: ['center', null, 'start'],
          gap: '8px',
        }}
      >
        <ColoredIcon
          icon={icon.icon}
          color="#107EFF"
          rootSx={{ flexShrink: 0 }}
        />
        <Text
          sx={{
            fontSize: ['15px', '16px', '16px'],
            fontWeight: 'bold',
            lineHeight: ['18px', '20px', '26px'],
            textTransform: 'capitalize',
          }}
        >
          {title}
        </Text>
      </Flex>
      <Text
        sx={{
          fontSize: ['13px', '14px', '14px'],
          lineHeight: '21px',
          color: 'textMedium',
          flexGrow: 1,
        }}
      >
        {description}
      </Text>
      {cta ? (
        <Flex
          key={cta.text}
          sx={{ fontSize: '14px', lineHeight: '17px', fontWeight: 'semibold' }}
        >
          <CallToAction
            {...cta}
            ctaSx={{ display: 'flex', alignItems: 'center' }}
          >
            {cta.text}
            {cta.type === 'LINK' ? (
              <Icon iconName={cta.icon} iconSx={CTA_ICON_STYLES} />
            ) : null}
          </CallToAction>
        </Flex>
      ) : null}
    </Flex>
  );
};
