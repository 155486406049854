// libs
import React, { ReactElement } from 'react';

// components
import { Flex, Box, ThemeUIStyleObject } from 'theme-ui';
import { Icon } from '@sprinklr/shared-lib/components/icon';

export const ColoredIcon = ({
  icon,
  color,
  rootSx,
}: {
  icon: string;
  color: string;
  rootSx?: ThemeUIStyleObject;
}): ReactElement => (
  <Flex
    sx={{
      width: '35px',
      height: '35px',
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'center',
      ...rootSx,
    }}
  >
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        opacity: 0.1,
        backgroundColor: color,
        borderRadius: '50%',
      }}
    />
    <Icon
      iconName={icon}
      iconSx={{
        position: 'absolute',
        height: '20px',
        width: '20px',
        fill: color,
      }}
    />
  </Flex>
);
