// libs
import React, { ReactElement } from 'react';

// components
import { Flex, Text } from 'theme-ui';
import { CallToAction } from '@sprinklr/shared-lib';
import Media from '@sprinklr/shared-lib/components/media';

// types
import { CTA } from '@sprinklr/shared-lib/@types/entities';
import { MediaProps } from '@sprinklr/shared-lib/components/media/types';

type FeatureInfoProps = {
  title: string;
  description: string;
  subDescription?: string;
  media: MediaProps;
  cta?: CTA;
};

export const FeatureInfo = (props: FeatureInfoProps): ReactElement => {
  const { title, description, subDescription, cta, media } = props;

  return (
    <Flex
      sx={{
        width: '100%',
        flexDirection: ['column', 'column', 'row'],
        gap: ['24px', '24px', '72px'],
        justifyContent: 'center',
      }}
    >
      <Flex
        sx={{
          alignItems: ['center', 'center', 'start'],
          flexDirection: 'column',
          flexBasis: 0,
          flexGrow: 1,
          gap: 2,
          justifyContent: 'center',
        }}
      >
        <Text
          sx={{
            fontSize: [2, 2, 3],
            textAlign: ['center', 'center', 'left'],
            lineHeight: ['20px', '20px', '24px'],
            fontWeight: 'semiBold',
            textTransform: 'capitalize',
            color: 'primary',
          }}
        >
          {title}
        </Text>
        <Text
          sx={{
            fontSize: [4, 6, 10],
            lineHeight: ['24px', '30px', '36px'],
            fontWeight: 'bold',
            textAlign: ['center', 'center', 'left'],
          }}
        >
          {description}
        </Text>
        {subDescription ? (
          <Text
            sx={{
              fontSize: 2,
              lineHeight: ['22px', '22px', '24px'],
              color: 'textMedium',
              textAlign: ['center', 'center', 'left'],
            }}
          >
            {subDescription}
          </Text>
        ) : null}
        {cta ? (
          <CallToAction
            {...cta}
            ctaSx={{
              backgroundColor: '#107eff1a',
              borderWidth: 0,
              marginTop: '12px',
            }}
            ctaTextSx={{ fontSize: 1, textTransform: 'capitalize' }}
          />
        ) : null}
      </Flex>
      <Media
        {...media}
        source={media?.asset}
        mediaSx={{ flexShrink: 0, flexGrow: 1 }}
      />
    </Flex>
  );
};
