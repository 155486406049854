// libs
import React, { ReactElement } from 'react';

// components
import { Grid } from 'theme-ui';
import { SubFeatureCard } from './SubFeatureCard';

// types
import { SubFeature } from '../../types';

export const SubFeatures = ({
  subFeatures,
}: {
  subFeatures: SubFeature[];
}): ReactElement => (
  <Grid
    columns={[2, 2, 4]}
    sx={{ gap: ['24px', '36px'], justifyItems: 'center' }}
  >
    {subFeatures.map(subFeature => (
      <SubFeatureCard key={subFeature.id} subFeature={subFeature} />
    ))}
  </Grid>
);
